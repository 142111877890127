export default {
	AGENDA: 'Dashboard/AgendaWidget',
	ACTIVITIES: 'Dashboard/ActivitiesWidget',
	CASES: 'Dashboard/CasesWidget',
	APPOINTMENTS: 'Dashboard/AppointmentsWidget',
	PROFILE: 'Dashboard/ProfileWidget',
	NAVIGATION: 'Generic/Navigation/Links',
  MU_NAVIGATION: 'Generic/Navigation/Links_MU',
	LOGOUT: 'Generic/Navigation/Logout',
	GOBACKNAVIGATION: 'Generic/Navigation/GoBack',
	UNITS: 'Generic/Navigation/Units',
	CHAT: 'V2__Chat/Chat',
	HEADER: 'V2__Chat/Header',

	/**NHS Widgets Dashboard */
	WELCOME: 'Journals/NHS/WelcomeWidget',
	WEIGHT: 'Journals/NHS/WeightWidget',
	FOOD: 'Journals/NHS/FoodWidget',
	MOOD: 'Journals/NHS/MoodWidget',
	EXERCISE: 'Journals/NHS/ExerciseWidget',
	DAILYREPORT: 'Journals/NHS/DailyReportWidget',
	MEETING: 'Journals/NHS/NextMeetingWidget',
	COMMUNITY: 'Journals/NHS/CommunityWidget',
	LIBRARY: 'Journals/NHS/LibraryWidget',
	MYFILES: 'Journals/NHS/Myfiles',

	/**NHS Journals Widgets */
	/**Smart Goals */
	GOBACKSMARTGOALS: 'Journals/SmartGoals/GoBack',
	SETWEIGHTGOALS: 'Journals/SmartGoals/WeightGoals',
	/**Weight Widgets */
	GOBACKWEIGHT: 'Journals/Weight/GoBack',
	UPDATEWEIGHT: 'Journals/Weight/Weight',
	LOGSWEIGHT: 'Journals/Weight/Logs',
	/**Food Widgets */
	GOBACKFOOD: 'Journals/Food/GoBack',
	UPDATEFOOD: 'Journals/Food/Food',
	LOGSFOOD: 'Journals/Food/FoodLogs',
	/**Mood Widgets */
	GOBACKMOOD: 'Journals/Mood/GoBack',
	UPDATEMOOD: 'Journals/Mood/Mood',
	// UPDATEMOOD: 'Journals/Mood/MoodClass',
	LOGSMOOD: 'Journals/Mood/MoodLogs',
	/**Exercise Widgets */
	GOBACKEXERCISE: 'Journals/Exercise/GoBack',
	UPDATEEXERCISE: 'Journals/Exercise/Exercise',
	LOGSEXERCISE: 'Journals/Exercise/ExerciseLogs',
	/**Daily Report Widgets */
	GOBACKDAILYREPORT: 'Journals/DailyReport/GoBack',
	DAILYREPORTS: 'Journals/DailyReport/DailyReport',
	DAILYREPORTFOODQUESTIONNAIRE: 'Journals/DailyReport/DailyReportFoodQuestionnaire',
	DAILY_LOGS_BUTTONS: 'Journals/DailyReport/DailyLogsButtons',
	/**Community Widgets */
	GOBACKCOMMUNITY: 'Journals/Community/GoBack',
	COMMUNITYDETAILS: 'Journals/Community/Community',
	ACTIVITYLIST: 'Journals/Community/ActivityList',
	ACTIVITY_SUMMARY: 'Journals/Community/ActivitySummary',
	/**Library Widgets */
	GOBACKLIBRARY: 'Journals/Library/GoBack',
	LIBRARYFILE: 'Journals/Library/LibraryLogs',
	/**Files Widgets */
	GOBACKFILES: 'Journals/Files/GoBack',
	MYFILESTICKET: 'Journals/Files/FilesLogs',
	/**Dashboard */
	DASHBOARD: 'Dashboard/Dashboard',

	/**TICKET */
	TICKET_OVERVIEW: 'Tickets/Layout',
	GOBACKFILETAGS: 'Tickets/TicketTags/GoBack',
	TAGSFILE: 'Tickets/TicketTags/TagsLogs',

  /**SEARCH */
	ANALYTIC_TICKETS_LOGS: 'Analytic/AnalyticTicketsLogs',
  ANALYTIC_TICKET_LOGS: 'Analytic/AnalyticTicketLogs',
	
	/**INVITE */
	INVITE_OVERVIEW: 'Invite/Overview',
	INVITE_SINGLE_FORM: 'Tickets/Invite/InviteSingleForm',

	/**GENERIC */
	PAGE_TITLE: 'Generic/PageTitle',

  /**MODAL */
	EVENT_CANCEL: 'Generic/CancelEvent',
	PRECALL: 'VideoConference/PrecallWidget',

  /**QUESTIONNAIRES */
  SURVEY: 'Surveys/Survey',

  /**STATISTIC */
  STATISTIC_OVERVIEW: 'Statistics/Overview',
	STATISTIC_CONTACTS: 'Statistics/StatisticContacts',
  STATISTIC_CONTACTS_INTERVAL: 'Statistics/StatisticContactsInterval',
  STATISTIC_CONTACTS_CATEGORY: 'Statistics/StatisticContactsCategory',
  STATISTIC_CONTACTS_CATEGORY_GROUP: 'Statistics/StatisticContactsCategoryGroup',
  STATISTIC_CONTACTS_TYPE: 'Statistics/StatisticContactsType',
  STATISTIC_CONTACTS_TAG_GROUPS: 'Statistics/StatisticContactsTagGroup',
  STATISTIC_TICKETS_REFERRAL: 'Statistics/StatisticTicketsReferral',
  STATISTIC_CONTACTS_GENDER: 'Statistics/StatisticContactsGender',
  STATISTIC_CONTACTS_PRONOUN: 'Statistics/StatisticContactsPronoun',
  
  /**QUESTIONNAIRES */
  FREETIME_FORM: 'Users/MyPages/Calendar/FreeTime',

  /**USER */
  USER_FORM: 'Users/UserForm',
};
